// App.js
import { Routes, Route } from 'react-router-dom'
import Registration from './Registration'
import Complete from './Complete'
import Ping from './Ping'

const App = () => {
 return (
    <>
       <Routes>
          <Route path="/" element={<Registration />} />
          <Route path="/complete" element={<Complete />} />
          <Route path="/ping" element={<Ping />} />
       </Routes>
    </>
 )
}

export default App