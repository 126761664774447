import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useTranslation } from "react-i18next"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.totemadventure.com/">
        Totem Adventure
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export default function Ping() {
  const { t } = useTranslation('common');

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(true)
  const [errorMsg, setErrorMsg] = React.useState('')

  const onPing = () => {
    const jsonData = {

    }

    setSuccess(true)
    setLoading(true)
    setErrorMsg('')

    fetch("http://vps-520efbaa.vps.ovh.net:10000/api/registration", {
      method: "POST",
      mode: "cors",
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'secret': '%y9e9p2KHSXrbeZVJ6zHqZ!xob4kXjKDzgfUy5XX*YroTC86syBKxp!@Xoa9%%gX%V!hvbee&4$tLz'
      },
      body: JSON.stringify(jsonData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status)
        else return response.json()
      })
      .then(data => {
        console.log("registration id: " + data.id)
        setSuccess(true)
        setLoading(false)
      })
      .catch(error => {
        console.error(error.message)
        setErrorMsg(error.message)
        setSuccess(false)
        setLoading(false)
      })
  }


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>

          <CardMedia
            height="150"
            component="img"
            image="logo.jpeg"
            sx={{ mb: 4, objectFit: "contain" }} />

          <Typography variant="h5">
            {t("thanks")}
          </Typography>

          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              onClick={onPing}
              fullWidth
              disabled={loading}
              sx={{ mt: 3, mb: 0 }} >
              Ping
            </Button>

            <CircularProgress
              size={24}
              sx={{
                display: loading ? 'block' : 'none',
                position: 'absolute',
                top: '50%',
                left: '50%'
              }}
            />
          </Box>
        </Box>
        <Typography variant="h6" align="center" sx={{
          display: !success ? 'block' : 'none'
        }}>
          {errorMsg}
        </Typography>

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}