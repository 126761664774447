import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import detector from "i18next-browser-languagedetector"
import common_en from "./translations/en/common.json"
import common_it from "./translations/it/common.json"


const resources = {
  en: { common: common_en },
  it: { common: common_it }
}

const options = {
  //order: ['querystring', 'navigator'],
  //lookupQuerystring: 'lng'
}

i18next
  .use(detector)
  .init({
    interpolation: { escapeValue: false },
    detection: options,
    resources: resources,
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: "en"
  })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
